import {
  CheckOutlined,
  CopyOutlined,
  CustomerServiceOutlined,
  ExclamationCircleOutlined,
  FileImageOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";
import {App, Button, Card, Empty, Form, Image, Pagination, Spin, Tabs, Upload} from "antd";
import React, {forwardRef, ReactNode, useEffect, useImperativeHandle, useState} from "react";
import {RcFile} from "antd/es/upload";
import './index.less';
import {ModalForm, ProCard, ProFormText} from "@ant-design/pro-components";
import {DeleteOutline} from "antd-mobile-icons";
import {formSubmitRequest} from "@oakjs/antd-pro/dist/cjs/services/base/ServiceRequest";
import {ProFormTextArea, ProFormUploadButton} from "@ant-design/pro-form";
import {copyText, getVideoInfo} from "../../utils/CommonUtil";


export enum E_MaterialType {
  IMAGE = 'image',
  VOICE = 'voice',
  VIDEO = 'video',
}

interface I_TabItem {
  key: E_MaterialType;
  label: string;
  icon: ReactNode;
  accept: string;
  acceptDesc: string;
}

interface I_Pagination {
  pageSize?: number
  pageNo?: number
  total?: number
}

interface I_UploadVideoForm {
  files: RcFile[]
  title: string
  introduction: string
}

const TAB_ITEMS: I_TabItem[] = [
  {
    key: E_MaterialType.IMAGE,
    label: `图片`,
    icon: <FileImageOutlined/>,
    accept: 'image/jpeg,image/png,image/gif,image/bmp,image/jpg,.png,.gif,.bmp,.jpg,.jpeg',
    acceptDesc: '支持 bmp/png/jpeg/jpg/gif 格式，大小不超过 2M'
  },
  {
    key: E_MaterialType.VOICE,
    label: `语音`,
    icon: <CustomerServiceOutlined/>,
    accept: 'audio/mp3,audio/wma,audio/wav,audio/amr,.mp3,.wma,.wav,.amr',
    acceptDesc: '支持 mp3/wma/wav/amr格式，文件大小不超过 2M，播放长度不超过 60s'
  },
  {
    key: E_MaterialType.VIDEO,
    label: `视频`,
    icon: <VideoCameraOutlined/>,
    accept: 'video/mp4,.mp4',
    acceptDesc: '支持 MP4格式，文件大小不超过 10MB'
  },
];
const CARD_WIDTH: number = 320;
const CARD_HEIGHT: number = 200;
const CARD_SELECT_WIDTH: number = 240;
const CARD_SELECT_HEIGHT: number = 120;
type I_Props = {
  weixinAppId: string;
  mode: 'select' | 'manage';
  onSelected?: (data: any) => void;
};
const WxMpMaterial = forwardRef((props: I_Props, ref) => {
  const [activeTab, setActiveTab] = useState<I_TabItem>(TAB_ITEMS[0])
  const [loading, setLoading] = useState(false)
  const [videoFile, setVideoFile] = useState<RcFile>()
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState<number>(0)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [list, setList] = useState<[]>([])
  const [appId, setAppId] = useState<string>(props.weixinAppId)
  const [pages, setPages] = useState<I_Pagination>({
    pageSize: 10,
    pageNo: 1,
    total: 0
  })
  const [uploadVideoForm] = Form.useForm<I_UploadVideoForm>();
  const {message, modal} = App.useApp();
  const onVisibleChange = (value: boolean) => {
    setPreviewVisible(value)
  }
  useEffect(() => {
    getList({
      pageSize: 10,
      pageNo: 1,
      total: 0
    }).then()
  }, [activeTab, appId])
  useImperativeHandle(ref, () => ({
    refresh
  }))
  const refresh = (_appId: string) => {
    if (appId !== _appId) {
      setAppId(_appId);
    }
    if (activeTab.key !== TAB_ITEMS[0].key) {
      setActiveTab(TAB_ITEMS[0]);
    }
  }
  /**
   * @description 获取列表
   */
  const getList = async (pagination?: I_Pagination) => {
    const newPagination = {
      pageSize: props.mode === 'manage' ? 10 : 6,
      ...pages,
      ...pagination
    }
    const resp = await formSubmitRequest(`/weixinmpmaterial/page`, {
      appId: appId,
      type: activeTab.key,
      permanent: true,
      queryPage: newPagination.pageNo,
      querySize: newPagination.pageSize,
      queryType: 'QUERY_BOTH',
    });
    if (!resp.success) {
      message.error(resp.message);
      return;
    }
    newPagination.total = resp.data?.total || 0;
    setPages(newPagination);
    setList(resp.data?.records || [])
  }

  /**
   * @description 上传文件,不走组件的action
   * @param file
   * @param title
   * @param introduction
   * @param coverUrl
   */
  const uploadMaterialBefore = async (file: RcFile, title?: string, introduction?: string, coverUrl?: string) => {
    setLoading(true);
    let accepts = activeTab.accept.split(',');
    // 检测文件类型
    let isType = accepts.some(accept => accept === file.type) || accepts.some(accept => file.name.endsWith(accept));
    try {
      if (!isType) {
        throw new Error(`上传${activeTab.label}格式不对!`);
      }
      if (activeTab.key !== E_MaterialType.VIDEO && !(file.size / 1024 / 1024 < 2)) {
        throw new Error(`上传${activeTab.label}大小不能超过 2M!`);
      }
      if (activeTab.key === E_MaterialType.VIDEO && !(file.size / 1024 / 1024 < 10)) {
        throw new Error('上传视频大小不能超过 10M!');
      }
      let formData = new FormData();
      formData.append('file', file);
      formData.append('type', activeTab.key);
      formData.append('appId', appId);
      title && formData.append('title', title);
      introduction && formData.append('introduction', introduction);
      coverUrl && formData.append('coverUrl', coverUrl);
      const resp = await formSubmitRequest('/weixinmpmaterial/uploadPermanent', formData);
      if (!resp.success) {
        throw new Error(resp.message);
      }
      message.success('上传成功！');
    } catch (e: any) {
      message.error((e as Error).message);
      return false;
    } finally {
      setLoading(false);
    }
    await getList()
    return true;
  }

  const handleDeleteClick = (id: string) => {
    modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined/>,
      content: `此操作会删除公众号的永久素材，是否删除？`,
      okText: '确认',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const resp = await formSubmitRequest(`/weixinmpmaterial/del`, {id});
        if (!resp.success) {
          message.error(resp.message);
          return;
        }
        message.success('删除成功！');
        getList()
      }
    });
  }

  const actionsButton = (item: any) => {
    if (props.mode === 'manage') {
      return ([
        <Button color="danger" variant="solid" shape="circle" icon={<DeleteOutline/>}
                onClick={() => handleDeleteClick(item.id)}/>,
        <Button variant="solid" shape="circle" icon={<CopyOutlined/>}
                onClick={() => copyText(item.mediaId)}/>
      ])
    }
    return ([
      <Button variant="solid" shape="circle" icon={<CheckOutlined/>}
              onClick={() => props.onSelected?.(item)}/>
    ])

  };

  return (
    <>
      <Spin spinning={loading} tip="加载中" fullscreen/>
      <Tabs
        activeKey={activeTab.key}
        items={TAB_ITEMS}
        onChange={val => {
          setActiveTab(TAB_ITEMS.find(item => item.key === val)!);
        }}
      />
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {activeTab.key != E_MaterialType.VIDEO &&
          <Upload
            accept={activeTab.accept}
            beforeUpload={(file: RcFile) => uploadMaterialBefore(file)}
            multiple={false}
            maxCount={1}
            withCredentials={true}
            showUploadList={false}
          >
            <div className={'upload-container'}>
              <Button type={"primary"}>点击上传</Button>
              <div style={{marginLeft: '10px', color: '#858585'}}>{activeTab.acceptDesc}</div>
            </div>
          </Upload>
        }
        {activeTab.key === E_MaterialType.VIDEO &&
          <ModalForm<I_UploadVideoForm>
            title="上传视频"
            width={420}
            trigger={
              <div className={'upload-container'}>
                <Button type={"primary"}>点击上传</Button>
                <div style={{marginLeft: '10px', color: '#858585'}}>{activeTab.acceptDesc}</div>
              </div>
            }
            form={uploadVideoForm}
            autoFocusFirstInput
            modalProps={{
              zIndex: 900,
              destroyOnClose: true,
              onCancel: () => {
                setVideoFile(undefined);
              },
            }}
            onFinish={async (values) => {
              if (!videoFile) {
                message.error('请上传视频文件');
                return false;
              }

              setLoading(true);
              const {coverUrl} = await getVideoInfo(videoFile);
              return await uploadMaterialBefore(videoFile, values.title, values.introduction, coverUrl);
            }}
          >
            <ProFormUploadButton
              max={1}
              label="视频文件"
              name="files"
              action={async (file: RcFile) => {
                setVideoFile(file);
                return '';
              }}
              fieldProps={{
                name: 'files',
                listType: 'picture-card',
                multiple: false,
                maxCount: 1,
                withCredentials: true,
                onRemove: () => setVideoFile(undefined)
              }}
              accept={activeTab.accept}
              rules={[{required: true, message: '请上传视频文件'}]}
            />
            <ProFormText
              name="title"
              label="标题"
              placeholder="标题将展示在相关播放页面，建议填写清晰、准确、生动的标题"
              rules={[{required: true, message: '请填写标题'}]}
            />
            <ProFormTextArea
              required
              name="introduction"
              label="描述"
              placeholder="介绍语将展示在相关播放页面，建议填写简洁明确、有信息量的内容"
              fieldProps={{
                rows: 4,
                maxLength: 100,
              }}
              rules={[{required: true, message: '请填写描述'}]}
            />
          </ModalForm>
        }

      </div>
      <ProCard split="vertical" style={{marginTop: '20px', padding: '20px'}}>
        {list.length <= 0 ? <Empty/> :
          <>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}}>

              <Image.PreviewGroup
                items={list.map((item: any) => item.url)}
                preview={{
                  visible: previewVisible,
                  current: currentPreviewIndex,
                  destroyOnClose: true,
                  countRender: () => null,
                  onVisibleChange: onVisibleChange,
                  onChange(current) {
                    setCurrentPreviewIndex(current)
                  },
                  imageRender: (originalNode, info) => {
                    const current = info.current
                    let currentItem = list[current] as any;
                    if (activeTab.key === E_MaterialType.VIDEO) {
                      return <video muted controls src={currentItem.url} style={{maxHeight: '80%', maxWidth: '80%'}}/>
                    } else if (activeTab.key === E_MaterialType.IMAGE) {
                      return originalNode
                    } else if (activeTab.key === E_MaterialType.VOICE) {
                      return <audio controls src={currentItem.url} style={{maxHeight: '80%', maxWidth: '80%'}}/>
                    } else {
                      return null
                    }
                  },
                }}
              >
                {list.map((item: any, index) => {
                  return (
                    <div key={index}>
                      <Card
                        style={{width: props.mode === 'manage' ? CARD_WIDTH : CARD_SELECT_WIDTH}}
                        className={'card-preview-container'}
                        bordered
                        hoverable
                        cover={
                          <>
                            {/* 图片 */}
                            {activeTab.key === E_MaterialType.IMAGE &&
                              <Image
                                width={props.mode === 'manage' ? CARD_WIDTH : CARD_SELECT_WIDTH}
                                height={props.mode === 'manage' ? CARD_HEIGHT : CARD_SELECT_HEIGHT}
                                src={item.thumbImage}
                                onClick={() => setCurrentPreviewIndex(index)}
                                preview={{
                                  mask: null
                                }}
                              />
                            }
                            {/* 音频 */}
                            {activeTab.key === E_MaterialType.VOICE &&
                              <div className={'card-preview-cover'}
                                   style={{
                                     width: props.mode === 'manage' ? CARD_WIDTH : CARD_SELECT_WIDTH,
                                     height: props.mode === 'manage' ? CARD_HEIGHT : CARD_SELECT_HEIGHT,
                                   }}
                                   onClick={() => {
                                     setCurrentPreviewIndex(index);
                                     setPreviewVisible(true);
                                   }}
                              >
                                <CustomerServiceOutlined className={'card-preview-cover-icon'}/>
                              </div>
                            }
                            {/* 视频 */}
                            {activeTab.key === E_MaterialType.VIDEO &&
                              <div className={'card-preview-cover'}>
                                {item.thumbImage
                                  ? <Image
                                    width={props.mode === 'manage' ? CARD_WIDTH : CARD_SELECT_WIDTH}
                                    height={props.mode === 'manage' ? CARD_HEIGHT : CARD_SELECT_HEIGHT}
                                    src={item.thumbImage}
                                    onClick={() => setCurrentPreviewIndex(index)}
                                    preview={{
                                      mask: null
                                    }}
                                  />
                                  // 降级处理：无封面展示图标
                                  : <div
                                    className={'card-preview-cover'}
                                    style={{
                                      width: props.mode === 'manage' ? CARD_WIDTH : CARD_SELECT_WIDTH,
                                      height: props.mode === 'manage' ? CARD_HEIGHT : CARD_SELECT_HEIGHT
                                    }}
                                    onClick={() => {
                                      setCurrentPreviewIndex(index);
                                      setPreviewVisible(true);
                                    }}>
                                    <VideoCameraOutlined className={'card-preview-cover-icon'}/>
                                  </div>
                                }
                              </div>
                            }
                          </>
                        }
                        actions={actionsButton(item)}
                      >
                        <div className={'card-preview-title-container'}>
                          <div className={'card-preview-title'}>
                            {item.name}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </div>
            <Pagination
              style={{marginTop: 10}}
              align="end"
              pageSize={pages.pageSize}
              current={pages.pageNo}
              total={pages.total}
              onChange={(pageNo, pageSize) => {
                getList({
                  pageNo,
                  pageSize
                })
              }}
            />
          </>
        }
      </ProCard>
    </>
  );
})

export default WxMpMaterial;
