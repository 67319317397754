export const dataURLtoBlob = (base64: string, mimeType?: string): Blob => {
  // 移除Base64的头部信息，只保留实际的Base64数据
  const base64Data = base64.split(',')[1];
  // 解码Base64数据并创建Blob
  const binaryData = window.atob(base64Data);
  const buffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(buffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], {type: mimeType}); // 根据Base64头部信息设置正确的类型
  return blob;
}
