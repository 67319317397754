import ProFormDatePicker from "./DatePicker";
import ProFormDatePickerMonth from "./MonthPicker";
import ProFormDatePickerQuarter from "./QuarterPicker";
import ProFormDatePickerWeek from "./WeekPicker";
import ProFormDatePickerYear from "./YearPicker";
var ExportComponent = ProFormDatePicker;
ExportComponent.Week = ProFormDatePickerWeek;
ExportComponent.Month = ProFormDatePickerMonth;
ExportComponent.Quarter = ProFormDatePickerQuarter;
ExportComponent.Year = ProFormDatePickerYear;
// @ts-ignore
// eslint-disable-next-line no-param-reassign
ExportComponent.displayName = 'ProFormComponent';
export default ExportComponent;