import {useImperativeHandle, useRef, useState} from "react";
import {ModalForm, ProFormInstance, ProFormText} from "@ant-design/pro-form";
import {changePwdRequest} from "@oakjs/antd-pro/dist/cjs/services/base/ServiceRequest";
import {message} from "antd";

// 操作类型
export declare type ChangePwdModalActionType = {
  open: () => void
  close: () => void
}

function ChangePwdModal(props: {actionRef?: React.MutableRefObject<ChangePwdModalActionType | undefined> | ((actionRef: ChangePwdModalActionType) => void) }) {

  // 显示
  const [visible, setVisible] = useState<boolean>(false);

  const formRef = useRef<ProFormInstance>();

  const onOpen = () => {
    // 表单复位
    formRef.current?.resetFields();
    setVisible(true);
  }

  const onClose = () => {
    setVisible(false);
  }

  // @ts-ignore
  useImperativeHandle<ChangePwdModalActionType>(props.actionRef, () => ({
    open: onOpen,
    close: onClose,
  }));

  const checkConfirmPassword = (rule: any, value: string) => {
    const newPassword = formRef.current?.getFieldValue('newPassword');
    if (newPassword && newPassword !== value) {
      return Promise.reject('确认密码与新密码不一致');
    } else {
      return Promise.resolve();
    }
  }

  return <>

    {/* 弹出表单 */}
    {
      visible && <ModalForm
        width={420}
        formRef={formRef}
        title="修改密码"
        open={visible}
        autoFocusFirstInput={true}
        onFinish={async (values) => {
          const {oldPassword, newPassword} = values;
          changePwdRequest(oldPassword, newPassword).then((resp) => {
            if (resp.success) {
              message.success({content: '修改成功', key: 'process'});
              // messageApi.success('修改成功');// TODO 无法显示
              setVisible(false);
              return true;
            } else {
              message.error({content: resp.message, key: 'process'});
              // messageApi.error(resp.message);
              setVisible(false);
              return false;
            }
          })
        }}
        onOpenChange={(v) => {
          if (!v) {
            onClose();
          }
          setVisible(v);
        }}
      >
        <ProFormText.Password required={true} name="oldPassword" placeholder={'输入密码'} validateFirst={true} label={'密码'} rules={[{
          required: true,
          message: '请输入密码'
        }, {
          min: 6,
          max: 20,
          message: '密码允许6-20位字符'
        }]}/>
        <ProFormText.Password required={true} name="newPassword" placeholder="输入新密码" validateFirst={true} label={'新密码'} rules={[{
          required: true,
          message: '请输入密码'
        }, {
          min: 6,
          max: 20,
          message: '密码允许6-20位字符'
        }]}/>
        <ProFormText.Password required={true} name="repassword" placeholder="再次输入新密码" validateFirst={true} label={'确认密码'} rules={[{
          required: true,
          message: '请输入密码'
        }, {
          min: 6,
          max: 20,
          message: '密码允许6-20位字符'
        }, {validator: checkConfirmPassword}]}/>

      </ModalForm>
    }
  </>
};
export default ChangePwdModal;
