var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormTags/index.tsx
var FormTags_exports = {};
__export(FormTags_exports, {
  default: () => FormTags_default
});
module.exports = __toCommonJS(FormTags_exports);
var import_pro_components = require("@ant-design/pro-components");
var import_react = __toESM(require("react"));
var import_ServiceRequest = require("../../services/base/ServiceRequest");
var import_OakTag = __toESM(require("./components/OakTag"));
var FormTags = ({
  dependencies,
  onChange,
  isEdit,
  ...props
}) => {
  const [selectItems, setSelectItems] = (0, import_react.useState)([]);
  const [mode, setMode] = (0, import_react.useState)("input");
  const isInitialRender = (0, import_react.useRef)(true);
  const {
    idKey,
    code,
    nameKey,
    separator = ","
  } = props.fieldProps;
  (0, import_react.useEffect)(() => {
    onChange == null ? void 0 : onChange(selectItems.length ? selectItems.join(separator) : "");
  }, [selectItems]);
  (0, import_react.useEffect)(() => {
    setMode(props.valueEnum || code ? "select" : "input");
    if (isEdit && props.value) {
      console.log("selectItems", props.value.split(separator));
      setSelectItems(props.value.split(separator));
    }
  }, []);
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement(import_pro_components.ProFormText, { hidden: true, ...props }), mode === "input" && /* @__PURE__ */ import_react.default.createElement(
    import_OakTag.default,
    {
      tagArray: selectItems,
      tagChange: (tags) => {
        setSelectItems(tags);
      }
    }
  ), mode === "select" && /* @__PURE__ */ import_react.default.createElement(
    import_pro_components.ProFormSelect,
    {
      mode: "tags",
      name: props,
      dependencies,
      params: { ...props.params },
      valueEnum: props.valueEnum,
      request: async (params) => {
        if (props.valueEnum) {
          let result = [];
          let keys = Object.keys(props.valueEnum);
          keys.forEach((key) => {
            result.push({
              value: props.valueEnum[key].status,
              label: props.valueEnum[key].text,
              disabled: props.valueEnum[key].disabled
            });
          });
          return result;
        }
        if (isInitialRender.current) {
          isInitialRender.current = false;
        } else {
          setSelectItems([]);
        }
        if ((dependencies == null ? void 0 : dependencies[0]) && (params == null ? void 0 : params[dependencies[0]]) && code) {
          const data = await (0, import_ServiceRequest.request)(
            `/${code}/select`,
            {
              method: "post",
              data: { [idKey]: params == null ? void 0 : params[dependencies == null ? void 0 : dependencies[0]] },
              requestType: "form"
            }
          ) || [];
          let result = [];
          data.forEach((item) => {
            var _a;
            let strArr = ((_a = item == null ? void 0 : item[nameKey]) == null ? void 0 : _a.split(separator)) || [];
            strArr.forEach((str) => {
              result.push({
                value: str,
                label: str
              });
            });
          });
          return result;
        }
        return [];
      },
      fieldProps: {
        value: selectItems,
        mode: "tags",
        onChange: (value, option) => {
          setSelectItems(value);
        }
      }
    }
  ));
};
var FormTags_default = FormTags;
