// 兼容代码-----------
import "antd/es/anchor/style";
import "antd/es/avatar/style";
import "antd/es/breadcrumb/style";
import "antd/es/divider/style";
import "antd/es/drawer/style";
import "antd/es/layout/style";
import "antd/es/list/style";
import "antd/es/menu/style";
import "antd/es/message/style";
import "antd/es/popover/style";
import "antd/es/select/style";
import "antd/es/space/style";
import "antd/es/spin/style";
import "antd/es/switch/style";
import "antd/es/tabs/style";
import "antd/es/tooltip/style";
import "antd/es/typography/style";
//----------------------

import { FooterToolbar } from "./components/FooterToolbar";
import { GridContent } from "./components/GridContent";
import { PageContainer, ProBreadcrumb, ProPageHeader } from "./components/PageContainer";
import { PageHeader } from "./components/PageHeader";
import { DefaultFooter } from "./components/Footer";
import { DefaultHeader } from "./components/Header";
import { PageLoading } from "./components/PageLoading";
import { SettingDrawer } from "./components/SettingDrawer";
import { TopNavHeader } from "./components/TopNavHeader";
import { WaterMark } from "./components/WaterMark";
import { RouteContext } from "./context/RouteContext";
import { getPageTitle } from "./getPageTitle";
import { ProLayout } from "./ProLayout";
import { getMenuData } from "./utils/getMenuData";
export * from "./components/Help";
export { DefaultFooter, DefaultHeader, FooterToolbar, getMenuData, getPageTitle, GridContent, PageContainer, PageHeader, PageLoading, ProBreadcrumb, ProLayout, ProPageHeader, RouteContext, SettingDrawer, TopNavHeader, WaterMark };
export default ProLayout;