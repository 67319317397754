var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormUpload/index.tsx
var FormUpload_exports = {};
__export(FormUpload_exports, {
  default: () => FormUpload_default
});
module.exports = __toCommonJS(FormUpload_exports);
var import_EnvVariableConfiguration = require("../../env/EnvVariableConfiguration");
var import_react = __toESM(require("react"));
var import_antd_img_crop = __toESM(require("antd-img-crop"));
var import_antd = require("antd");
var import_icons = require("@ant-design/icons");
var valueOf = (v) => {
  if (v) {
    const valueArr = v.split(",");
    const uploadFiles = [];
    const timestamp = Date.now();
    valueArr.forEach((url, index) => {
      const uploadFile = {
        uid: `__AUTO__${timestamp}_${index}__`,
        name: url,
        // TODO name获取
        status: "done",
        url,
        thumbUrl: url
      };
      uploadFiles.push(uploadFile);
    });
    return uploadFiles;
  }
  return [];
};
var { Text } = import_antd.Typography;
function FormUpload(props) {
  const {
    valueType = "image",
    options = {
      size: { text: "文件大小", status: "1MB" },
      type: { text: "文件类型", status: "" },
      count: { text: "文件数量", status: "1" },
      option: { text: "文件选项", status: "" },
      tooltip: { text: "提示", status: void 0 }
    },
    crop = false,
    value,
    onChange,
    ...rest
  } = props;
  const [files, setFiles] = (0, import_react.useState)([]);
  const [previewOpen, setPreviewOpen] = (0, import_react.useState)(false);
  const [previewImage, setPreviewImage] = (0, import_react.useState)("");
  (0, import_react.useEffect)(() => {
    if (value && value !== "") {
      console.log(`FormUpload init->${value}`);
      setFiles(() => valueOf(value));
    }
  }, []);
  const max = options["count"].status;
  const tooltip = options["tooltip"].status;
  const beforeUpload = () => {
    return (file, FileList) => {
      return true;
    };
  };
  const handleOnChange = (value2) => {
    var _a;
    let urls = "";
    (_a = value2.fileList) == null ? void 0 : _a.map((file) => {
      if (file.status === "done" && file.response) {
        const { thumbUrl, url } = file.response;
        if (thumbUrl && url) {
          file.thumbUrl = thumbUrl;
          file.url = url;
        }
      }
      if (file.url != null) {
        urls += `${file.url},`;
      }
      return file;
    });
    console.log(`FormUpload onChange->${urls}`, onChange);
    if (onChange && urls.endsWith(",")) {
      onChange(urls.substring(0, urls.length - 1));
    }
    setFiles(() => value2.fileList);
    return value2.fileList;
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  const build = (children) => {
    const isImage = valueType.startsWith("image");
    const isCrop = crop || options["option"].status !== "" && options["option"].status !== "0";
    let aspect = void 0;
    if (isCrop) {
      const aspectStr = options["option"].status;
      if (aspectStr.includes("/")) {
        var parts = aspectStr.split("/");
        aspect = parseFloat(parts[0]) / parseFloat(parts[1]);
      } else {
        aspect = parseFloat(aspectStr);
      }
    }
    return isCrop && isImage ? /* @__PURE__ */ import_react.default.createElement(
      import_antd_img_crop.default,
      {
        aspect,
        aspectSlider: true,
        zoomSlider: true,
        rotationSlider: true
      },
      children
    ) : /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, children);
  };
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, build(/* @__PURE__ */ import_react.default.createElement(
    import_antd.Upload,
    {
      ...rest,
      withCredentials: true,
      data: { type: "antd" },
      listType: valueType.startsWith("image") ? "picture-card" : "text",
      beforeUpload: beforeUpload(),
      maxCount: max,
      multiple: max > 1,
      fileList: files,
      onChange: handleOnChange,
      onPreview: valueType.startsWith("image") ? handlePreview : void 0,
      action: import_EnvVariableConfiguration.UPLOAD_URL
    },
    (!files || (files == null ? void 0 : files.length) < max) && /* @__PURE__ */ import_react.default.createElement(import_antd.Space, { direction: "vertical" }, /* @__PURE__ */ import_react.default.createElement(import_icons.UploadOutlined, null), /* @__PURE__ */ import_react.default.createElement(Text, { type: "secondary" }, `上传${valueType.startsWith("image") ? "图片" : "文件"}`))
  )), previewImage && /* @__PURE__ */ import_react.default.createElement(
    import_antd.Image,
    {
      wrapperStyle: { display: "none" },
      preview: {
        visible: previewOpen,
        onVisibleChange: (visible) => setPreviewOpen(visible),
        afterOpenChange: (visible) => !visible && setPreviewImage("")
      },
      src: previewImage
    }
  ));
}
var FormUpload_default = FormUpload;
