import _objectSpread from "@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { Keyframes } from '@ant-design/cssinjs';
import { useStyle as useAntdStyle } from '@ant-design/pro-provider';
export var techUiListActive = new Keyframes('techUiListActive', {
  '0%': {
    backgroundColor: 'unset'
  },
  '30%': {
    background: '#fefbe6'
  },
  '100%': {
    backgroundColor: 'unset'
  }
});
var genProListStyle = function genProListStyle(token) {
  var _row;
  return _defineProperty({}, token.componentCls, _defineProperty(_defineProperty({
    backgroundColor: 'transparent'
  }, "".concat(token.proComponentsCls, "-table-alert"), {
    marginBlockEnd: '16px'
  }), '&-row', (_row = {
    borderBlockEnd: "1px solid ".concat(token.colorSplit)
  }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_row, "".concat(token.antCls, "-list-item-meta-title"), {
    borderBlockEnd: 'none',
    margin: 0
  }), '&:last-child', _defineProperty({
    borderBlockEnd: 'none'
  }, "".concat(token.antCls, "-list-item"), {
    borderBlockEnd: 'none'
  })), '&:hover', _defineProperty(_defineProperty(_defineProperty(_defineProperty({
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    transition: 'background-color 0.3s'
  }, "".concat(token.antCls, "-list-item-action"), {
    display: 'block'
  }), "".concat(token.antCls, "-list-item-extra"), {
    display: 'flex'
  }), "".concat(token.componentCls, "-row-extra"), {
    display: 'block'
  }), "".concat(token.componentCls, "-row-subheader-actions"), {
    display: 'block'
  })), '&-card', _defineProperty({
    marginBlock: 8,
    marginInline: 0,
    paddingBlock: 0,
    paddingInline: 8,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }, "".concat(token.antCls, "-list-item-meta-title"), {
    flexShrink: 9,
    marginBlock: 0,
    marginInline: 0,
    lineHeight: '22px'
  })), "&".concat(token.componentCls, "-row-editable"), _defineProperty({}, "".concat(token.componentCls, "-list-item"), {
    '&-meta': {
      '&-avatar,&-description,&-title': {
        paddingBlock: 6,
        paddingInline: 0,
        '&-editable': {
          paddingBlock: 0
        }
      }
    },
    '&-action': {
      display: 'block'
    }
  })), "&".concat(token.componentCls, "-row-selected"), {
    backgroundColor: token.colorPrimaryBgHover,
    '&:hover': {
      backgroundColor: token.colorPrimaryBgHover
    }
  }), "&".concat(token.componentCls, "-row-type-new"), {
    animationName: techUiListActive,
    animationDuration: '3s'
  }), "&".concat(token.componentCls, "-row-type-inline"), _defineProperty({}, "".concat(token.componentCls, "-row-title"), {
    fontWeight: 'normal'
  })), "&".concat(token.componentCls, "-row-type-top"), {
    backgroundImage: "url('https://gw.alipayobjects.com/zos/antfincdn/DehQfMbOJb/icon.svg')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    backgroundSize: '12px 12px'
  }), '&-show-action-hover', _defineProperty({}, "".concat(token.antCls, "-list-item-action,\n            ").concat(token.proComponentsCls, "-card-extra,\n            ").concat(token.proComponentsCls, "-card-actions"), {
    display: 'flex'
  })), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_row, '&-show-extra-hover', _defineProperty({}, "".concat(token.antCls, "-list-item-extra"), {
    display: 'none'
  })), '&-extra', {
    display: 'none'
  }), '&-subheader', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '44px',
    paddingInline: 24,
    paddingBlock: 0,
    color: token.colorTextSecondary,
    lineHeight: '44px',
    background: 'rgba(0, 0, 0, 0.02)',
    '&-actions': {
      display: 'none'
    },
    '&-actions *': {
      marginInlineEnd: 8,
      '&:last-child': {
        marginInlineEnd: 0
      }
    }
  }), '&-expand-icon', {
    marginInlineEnd: 8,
    display: 'flex',
    fontSize: 12,
    cursor: 'pointer',
    height: '24px',
    marginRight: 4,
    color: token.colorTextSecondary,
    '> .anticon > svg': {
      transition: '0.3s'
    }
  }), '&-expanded', {
    ' > .anticon > svg': {
      transform: 'rotate(90deg)'
    }
  }), '&-title', {
    marginInlineEnd: '16px',
    wordBreak: 'break-all',
    cursor: 'pointer',
    '&-editable': {
      paddingBlock: 8
    },
    '&:hover': {
      color: token.colorPrimary
    }
  }), '&-content', {
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginBlock: 0,
    marginInline: 32
  }), '&-subTitle', {
    color: 'rgba(0, 0, 0, 0.45)',
    '&-editable': {
      paddingBlock: 8
    }
  }), '&-description', {
    marginBlockStart: '4px',
    wordBreak: 'break-all'
  }), '&-avatar', {
    display: 'flex'
  }), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_row, '&-header', {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-start',
    h4: {
      margin: 0,
      padding: 0
    }
  }), '&-header-container', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }), '&-header-option', {
    display: 'flex'
  }), '&-checkbox', {
    width: '16px',
    marginInlineEnd: '12px'
  }), '&-no-split', _defineProperty(_defineProperty({}, "".concat(token.componentCls, "-row"), {
    borderBlockEnd: 'none'
  }), "".concat(token.antCls, "-list ").concat(token.antCls, "-list-item"), {
    borderBlockEnd: 'none'
  })), '&-bordered', _defineProperty({}, "".concat(token.componentCls, "-toolbar"), {
    borderBlockEnd: "1px solid ".concat(token.colorSplit)
  })), "".concat(token.antCls, "-list-vertical"), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, "".concat(token.componentCls, "-row"), {
    borderBlockEnd: '12px 18px 12px 24px'
  }), '&-header-title', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  }), '&-content', {
    marginBlock: 0,
    marginInline: 0
  }), '&-subTitle', {
    marginBlockStart: 8
  }), "".concat(token.antCls, "-list-item-extra"), _defineProperty({
    display: 'flex',
    alignItems: 'center',
    marginInlineStart: '32px'
  }, "".concat(token.componentCls, "-row-description"), {
    marginBlockStart: 16
  })), "".concat(token.antCls, "-list-bordered ").concat(token.antCls, "-list-item"), {
    paddingInline: 0
  }), "".concat(token.componentCls, "-row-show-extra-hover"), _defineProperty({}, "".concat(token.antCls, "-list-item-extra "), {
    display: 'none'
  }))), "".concat(token.antCls, "-list-pagination"), {
    marginBlockStart: token.margin,
    marginBlockEnd: token.margin
  }), "".concat(token.antCls, "-list-list"), {
    '&-item': {
      cursor: 'pointer',
      paddingBlock: 12,
      paddingInline: 12
    }
  }), "".concat(token.antCls, "-list-vertical ").concat(token.proComponentsCls, "-list-row"), _defineProperty({
    '&-header': {
      paddingBlock: 0,
      paddingInline: 0,
      borderBlockEnd: 'none'
    }
  }, "".concat(token.antCls, "-list-item"), _defineProperty(_defineProperty(_defineProperty({
    width: '100%',
    paddingBlock: 12,
    paddingInlineStart: 24,
    paddingInlineEnd: 18
  }, "".concat(token.antCls, "-list-item-meta-avatar"), {
    display: 'flex',
    alignItems: 'center',
    marginInlineEnd: 8
  }), "".concat(token.antCls, "-list-item-action-split"), {
    display: 'none'
  }), "".concat(token.antCls, "-list-item-meta-title"), {
    marginBlock: 0,
    marginInline: 0
  }))))));
};
export function useStyle(prefixCls) {
  return useAntdStyle('ProList', function (token) {
    var proListToken = _objectSpread(_objectSpread({}, token), {}, {
      componentCls: ".".concat(prefixCls)
    });
    return [genProListStyle(proListToken)];
  });
}