import React, {useEffect, useState} from "react";
import {App, Button, Image, Modal, Tooltip} from "antd";
import WxMpMaterial, {E_MaterialType} from "../WxMpMaterial";
import {CustomerServiceOutlined, VideoCameraOutlined} from "@ant-design/icons";
import './index.less'
import {CloseOutline} from "antd-mobile-icons";
import {ProFormText} from "@ant-design/pro-components";
import {ProFormFieldItemProps} from "@ant-design/pro-form/es/typing";

interface I_Props {
  weixinAppId: string;
  editable?: boolean;
  onChange?: (value: string) => void,
}

interface I_MpMaterial {
  mediaId: string;
  thumbImage: string;
  name: string;
  type: E_MaterialType;
  url: string;
}

const WxMpMaterialLookup: React.FC<I_Props> = ({
                                                 weixinAppId,
                                                 onChange,
                                                 editable = false,
                                                 ...props
                                               }: I_Props & ProFormFieldItemProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);
  const [selectedMaterial, setSelectedMaterial] = useState<I_MpMaterial>();
  const [previewVisible, setPreviewVisible] = useState(false)
  const mpMaterialRef = React.useRef<{ refresh: (appId: string) => {} }>();
  const onVisibleChange = (value: boolean) => {
    setPreviewVisible(value)
  }

  const {message} = App.useApp();

  useEffect(() => {
    onChange?.(selectedMaterial?.mediaId || '');
  }, [selectedMaterial]);

  // 微信应用变化时清空选中项
  useEffect(() => {
    setSelectedMaterial(undefined);
    if (flag) {
      reselect();
      setFlag(false)
    }
  }, [weixinAppId]);

  // 解决编辑后列表组件不重新渲染的问题
  useEffect(() => {
    reselect();
  }, [(props as any).record]);

  const reselect = () => {
    let record = (props as any).record;
    if (record) {
      setSelectedMaterial({
        mediaId: record.mediaId,
        thumbImage: record.mediaCoverUrl,
        url: record.mediaUrl,
        name: record.mediaName,
        type: record.mediaType
      })
    }
  }

  useEffect(() => {
    reselect();
    setFlag(true)
  }, []);
  const showModal = () => {
    if (!weixinAppId) {
      message.error('请先选择公众号应用');
      return;
    }
    mpMaterialRef.current?.refresh(weixinAppId)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSelected = (material: I_MpMaterial) => {
    setSelectedMaterial(material);
    setIsModalOpen(false);
  }

  const ThumbImg = (material: I_MpMaterial) => {
    return (
      <>
        <Image.PreviewGroup
          items={[material.url]}
          preview={{
            visible: previewVisible,
            destroyOnClose: true,
            countRender: () => null,
            onVisibleChange: onVisibleChange,
            imageRender: (originalNode, info) => {
              const current = info.current
              if (material.type === E_MaterialType.VIDEO) {
                return <video muted controls src={material.url} style={{maxHeight: '80%', maxWidth: '80%'}}/>
              } else if (material.type === E_MaterialType.IMAGE) {
                return originalNode
              } else if (material.type === E_MaterialType.VOICE) {
                return <audio controls src={material.url} style={{maxHeight: '80%', maxWidth: '80%'}}/>
              } else {
                return null
              }
            },
          }}
        >
          {/* 图片 */}
          {material.type === E_MaterialType.IMAGE &&
            <Image
              width={32}
              height={32}
              src={material.thumbImage}
              preview={{
                mask: null
              }}
              className={'cursor-pointer'}
            />
          }
          {/* 音频 */}
          {material.type === E_MaterialType.VOICE &&
            <div className={'card-preview-cover cursor-pointer'}
                 style={{
                   width: 32,
                   height: 32,
                 }}
                 onClick={() => {
                   setPreviewVisible(true);
                 }}
            >
              <CustomerServiceOutlined className={'card-preview-cover-icon'}/>
            </div>
          }
          {/* 视频 */}
          {material.type === E_MaterialType.VIDEO &&
            <div className={'card-preview-cover'}>
              {material.thumbImage
                ? <Image
                  width={32}
                  height={32}
                  src={material.thumbImage}
                  preview={{
                    mask: null
                  }}
                  className={'cursor-pointer'}
                />
                // 降级处理：无封面展示图标
                : <div
                  className={'card-preview-cover cursor-pointer'}
                  style={{
                    width: 32,
                    height: 32
                  }}
                  onClick={() => {
                    setPreviewVisible(true);
                  }}>
                  <VideoCameraOutlined className={'card-preview-cover-icon'}/>
                </div>
              }
            </div>
          }
        </Image.PreviewGroup>
      </>
    )
  }

  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
        {selectedMaterial &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
            <ProFormText hidden={true} {...props} initialValue={selectedMaterial.mediaId}/>
            <div style={{textAlign: 'center'}}>
              <Tooltip title={selectedMaterial.name}>
                <div style={{width: 32, height: 32}}>
                  {ThumbImg(selectedMaterial)}
                </div>
              </Tooltip>
            </div>
            {
              editable &&
              <Button
                color={'danger'}
                variant={"outlined"}
                shape={"circle"}
                icon={<CloseOutline/>}
                size={"small"}
                style={{margin: '0 15px'}}
                onClick={() => setSelectedMaterial(undefined)}
              ></Button>
            }
          </div>
        }
        {
          editable &&
          <Button type="primary" onClick={showModal}>
            选择
          </Button>
        }
      </div>
      <Modal title="选择媒体" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={850} footer={null}>
        <WxMpMaterial ref={mpMaterialRef} weixinAppId={weixinAppId} mode={'select'} onSelected={handleSelected}/>
      </Modal>
    </>
  )
}
export default WxMpMaterialLookup;
