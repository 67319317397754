import {SettingDrawer} from '@ant-design/pro-layout';
import {Link, RunTimeLayoutConfig} from '@umijs/max';
import {history} from '@umijs/max';
import Footer from 'udf-admin-antd/lib/components/Footer';
import {buildBreadcrumb, initialState, InitialState} from "@oakjs/antd-pro/dist/cjs/provider/AppProvider";
import {mergeRoute, requestRoutes} from "@oakjs/antd-pro/dist/cjs/services/base/ServiceRequest";
import {LOGIN_URL, WELCOME_URL} from "@oakjs/antd-pro/dist/cjs/env/EnvVariableConfiguration";
import {checkPrivilege} from "@/utils/AccessUtil";
import React from "react";
import {App} from "antd";
import {AvatarDropdown, AvatarName} from 'udf-admin-antd/lib/components/RightContent/AvatarDropdown';
import defaultSettings from '../config/defaultSettings';
import { Question, SelectLang } from 'udf-admin-antd/lib/components/RightContent';
import {FundProjectionScreenOutlined} from "@ant-design/icons";


/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<InitialState> {
  return initialState(history, defaultSettings as any);
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
// @ts-ignore
export const layout: RunTimeLayoutConfig = ({initialState, setInitialState}) => {

  // @ts-ignore
  const splitMenus = initialState?.menus?.length > 1;
  console.log('RunTimeLayoutConfig.splitMenus [' + initialState?.menus?.length + '] = ' + splitMenus)

  return {
    actionsRender: () => [<Question key="doc" />, <SelectLang key="SelectLang" />],
    // @ts-ignore
    title: initialState?.getConfig?.('site_name'),
    breadcrumbRender: () => {
      console.log('history.location.pathname', history.location.pathname);
      console.log('initialState', initialState);
      // 按菜单路径构建
      return buildBreadcrumb(history.location.pathname, initialState);
    },
    disableContentMargin: false,
    avatarProps: {
      src: initialState?.currentUser?.avatar || '/def_avatar.png',
      title: <AvatarName/>,
      render: (_: any, avatarChildren: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    waterMarkProps: {
      //content: initialState?.currentUser?.fullName,
    },
    footerRender: () => <Footer/>,
    onPageChange: () => {
      const {location} = history;
      console.log(`onPageChange -> [${location.pathname}]`);
      console.log(initialState);
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== LOGIN_URL) {
        console.log(`to login->${location.pathname} -> ${LOGIN_URL}`);
        history.push(LOGIN_URL);
      }

      if (initialState?.currentUser && (location.pathname === LOGIN_URL || location.pathname === '/')) {
        history.push(initialState?.currentUser.welcomeUrl || WELCOME_URL)
      }

      // 如果是一级菜单重定向
      let indexOf = initialState?.menus?.map((m) => m.path).indexOf(location.pathname);
      if (indexOf !== -1) {
        // @ts-ignore
        const secondMenus: any[] = initialState?.menus?.[indexOf].routes;
        if (secondMenus && secondMenus.length > 0) {
          if (secondMenus[0].redirect) {
            history.push(secondMenus[0].redirect);
          } else if (secondMenus[0].routes && secondMenus[0].routes.length > 0) {
            // @ts-ignore
            history.push(secondMenus[0].routes[0].path);
          } else {
            // @ts-ignore
            history.push(secondMenus[0].path);
          }
        }
      }


      // 权限检查  access插件已关闭
      const pass = checkPrivilege(location.pathname, initialState);
      if (!pass) {
        // 到403
        console.log('无权访问')
        history.push('/403')
      }
    },
    layoutBgImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    splitMenus, // 把第一级的菜单放置到顶栏中 为了体验良好最好给每个一级菜单都设置一个重定向,这样可以防止切换到白屏页面
    menu: {
      locale: false,
      //params: initialState,
      request: async () => {
        console.log('initialState menus', initialState?.menus)
        // @ts-ignore //如果第一级菜单仅有1个，则直接使用子集
        return splitMenus ? initialState?.menus : initialState?.menus[0].routes
      }
    },
    links: [
      <Link to="/screen">
        <FundProjectionScreenOutlined/>
        <span>可视化大屏</span>
      </Link>
    ],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children: any) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <App>
          {children}
          <SettingDrawer
            disableUrlParams
            enableDarkTheme
            settings={initialState?.settings}
            onSettingChange={(settings) => {
              setInitialState((preInitialState: any) => ({
                ...preInitialState,
                settings,
              }));
            }}
          />
        </App>
      );
    },
    ...initialState?.settings,
  };
};


// @ts-ignore
export function patchClientRoutes({routes}) {

  console.log('app patchClientRoutes -> ', routes)
  // 加载服务端路由
  requestRoutes().then(loadRoutes => {
    // 合并服务端加载的路由
    // @ts-ignore
    mergeRoute(
      routes,
      loadRoutes
    );
  });
}

