import _objectSpread from "@babel/runtime/helpers/esm/objectSpread2";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useStyle as useAntdStyle } from '@ant-design/pro-provider';
var _map = [576, 768, 992, 1200].map(function (bp) {
    return "@media (max-width: ".concat(bp, "px)");
  }),
  _map2 = _slicedToArray(_map, 4),
  sm = _map2[0],
  md = _map2[1],
  lg = _map2[2],
  xl = _map2[3];
var genPageContainerStyle = function genPageContainerStyle(token) {
  var _token$layout, _token$layout2, _token$layout3, _token$layout4, _token$layout$pageCon, _token$layout5, _token$layout$pageCon2, _token$layout6, _token$layout7, _token$layout8, _token$layout$pageCon3, _token$layout9, _token$layout$pageCon4, _token$layout10, _token$layout$pageCon5, _token$layout11, _token$layout$pageCon6, _token$layout12;
  return _defineProperty({}, token.componentCls, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
    position: 'relative',
    '&-children-container': {
      paddingBlockStart: 0,
      paddingBlockEnd: (_token$layout = token.layout) === null || _token$layout === void 0 || (_token$layout = _token$layout.pageContainer) === null || _token$layout === void 0 ? void 0 : _token$layout.paddingBlockPageContainerContent,
      paddingInline: (_token$layout2 = token.layout) === null || _token$layout2 === void 0 || (_token$layout2 = _token$layout2.pageContainer) === null || _token$layout2 === void 0 ? void 0 : _token$layout2.paddingInlinePageContainerContent
    },
    '&-children-container-no-header': {
      paddingBlockStart: (_token$layout3 = token.layout) === null || _token$layout3 === void 0 || (_token$layout3 = _token$layout3.pageContainer) === null || _token$layout3 === void 0 ? void 0 : _token$layout3.paddingBlockPageContainerContent
    },
    '&-affix': _defineProperty({}, "".concat(token.antCls, "-affix"), _defineProperty({}, "".concat(token.componentCls, "-warp"), {
      backgroundColor: (_token$layout4 = token.layout) === null || _token$layout4 === void 0 || (_token$layout4 = _token$layout4.pageContainer) === null || _token$layout4 === void 0 ? void 0 : _token$layout4.colorBgPageContainerFixed,
      transition: 'background-color 0.3s',
      boxShadow: '0 2px 8px #f0f1f2'
    }))
  }, '& &-warp-page-header', _defineProperty(_defineProperty(_defineProperty(_defineProperty({
    paddingBlockStart: ((_token$layout$pageCon = (_token$layout5 = token.layout) === null || _token$layout5 === void 0 || (_token$layout5 = _token$layout5.pageContainer) === null || _token$layout5 === void 0 ? void 0 : _token$layout5.paddingBlockPageContainerContent) !== null && _token$layout$pageCon !== void 0 ? _token$layout$pageCon : 40) / 4,
    paddingBlockEnd: ((_token$layout$pageCon2 = (_token$layout6 = token.layout) === null || _token$layout6 === void 0 || (_token$layout6 = _token$layout6.pageContainer) === null || _token$layout6 === void 0 ? void 0 : _token$layout6.paddingBlockPageContainerContent) !== null && _token$layout$pageCon2 !== void 0 ? _token$layout$pageCon2 : 40) / 2,
    paddingInlineStart: (_token$layout7 = token.layout) === null || _token$layout7 === void 0 || (_token$layout7 = _token$layout7.pageContainer) === null || _token$layout7 === void 0 ? void 0 : _token$layout7.paddingInlinePageContainerContent,
    paddingInlineEnd: (_token$layout8 = token.layout) === null || _token$layout8 === void 0 || (_token$layout8 = _token$layout8.pageContainer) === null || _token$layout8 === void 0 ? void 0 : _token$layout8.paddingInlinePageContainerContent
  }, "& ~ ".concat(token.proComponentsCls, "-grid-content"), _defineProperty({}, "".concat(token.proComponentsCls, "-page-container-children-content"), {
    paddingBlock: ((_token$layout$pageCon3 = (_token$layout9 = token.layout) === null || _token$layout9 === void 0 || (_token$layout9 = _token$layout9.pageContainer) === null || _token$layout9 === void 0 ? void 0 : _token$layout9.paddingBlockPageContainerContent) !== null && _token$layout$pageCon3 !== void 0 ? _token$layout$pageCon3 : 24) / 3
  })), "".concat(token.antCls, "-page-header-breadcrumb"), {
    paddingBlockStart: ((_token$layout$pageCon4 = (_token$layout10 = token.layout) === null || _token$layout10 === void 0 || (_token$layout10 = _token$layout10.pageContainer) === null || _token$layout10 === void 0 ? void 0 : _token$layout10.paddingBlockPageContainerContent) !== null && _token$layout$pageCon4 !== void 0 ? _token$layout$pageCon4 : 40) / 4 + 10
  }), "".concat(token.antCls, "-page-header-heading"), {
    paddingBlockStart: ((_token$layout$pageCon5 = (_token$layout11 = token.layout) === null || _token$layout11 === void 0 || (_token$layout11 = _token$layout11.pageContainer) === null || _token$layout11 === void 0 ? void 0 : _token$layout11.paddingBlockPageContainerContent) !== null && _token$layout$pageCon5 !== void 0 ? _token$layout$pageCon5 : 40) / 4
  }), "".concat(token.antCls, "-page-header-footer"), {
    marginBlockStart: ((_token$layout$pageCon6 = (_token$layout12 = token.layout) === null || _token$layout12 === void 0 || (_token$layout12 = _token$layout12.pageContainer) === null || _token$layout12 === void 0 ? void 0 : _token$layout12.paddingBlockPageContainerContent) !== null && _token$layout$pageCon6 !== void 0 ? _token$layout$pageCon6 : 40) / 4
  })), '&-detail', _defineProperty({
    display: 'flex'
  }, sm, {
    display: 'block'
  })), '&-main', {
    width: '100%'
  }), '&-row', _defineProperty({
    display: 'flex',
    width: '100%'
  }, md, {
    display: 'block'
  })), '&-content', {
    flex: 'auto',
    width: '100%'
  }), '&-extraContent', _defineProperty(_defineProperty(_defineProperty(_defineProperty({
    flex: '0 1 auto',
    minWidth: '242px',
    marginInlineStart: 88,
    textAlign: 'end'
  }, xl, {
    marginInlineStart: 44
  }), lg, {
    marginInlineStart: 20
  }), md, {
    marginInlineStart: 0,
    textAlign: 'start'
  }), sm, {
    marginInlineStart: 0
  })));
};
export function useStyle(prefixCls, componentsToken) {
  return useAntdStyle('ProLayoutPageContainer', function (token) {
    var _token$layout13;
    var proCardToken = _objectSpread(_objectSpread({}, token), {}, {
      componentCls: ".".concat(prefixCls),
      layout: _objectSpread(_objectSpread({}, token === null || token === void 0 ? void 0 : token.layout), {}, {
        pageContainer: _objectSpread(_objectSpread({}, token === null || token === void 0 || (_token$layout13 = token.layout) === null || _token$layout13 === void 0 ? void 0 : _token$layout13.pageContainer), componentsToken)
      })
    });
    return [genPageContainerStyle(proCardToken)];
  });
}