import {dataURLtoBlob} from "./FileUtil";
import mime from 'mime'
import axios from "axios";
import {UPLOAD_URL} from "@oakjs/antd-pro/src/env/EnvVariableConfiguration";

import {message} from 'antd'
import DateFormatUtils, {DateFormatType} from "fengwuxp-common-utils/src/date/DateFormatUtils";

/**
 * 复制文本
 * @param {*} text 要复制的文本
 */
export function copyText(text: string) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success('复制成功')
    })
    .catch(() => {
      message.error('复制失败')
    })
  // if (window.clipboardData) {
  //   window.clipboardData.setData('text', text)
  // } else {
  //   ;(function () {
  //     document.oncopy = function (e) {
  //       e.clipboardData.setData('text', text)
  //       e.preventDefault()
  //       document.oncopy = null
  //     }
  //   })()
  //   document.execCommand('Copy')
  // }
}

/**
 * @description 获取视频文件第一帧图片
 */
export const getVideoInfo = async (
  file: File
): Promise<{
  coverUrl: string
  duration: number
  size: number
}> => {
  return new Promise((res, rej) => {
    const url = window.URL.createObjectURL(file)
    const video = document.createElement('video')
    video.src = url
    video.crossOrigin = 'anonymous'
    video.currentTime = 1
    const size = file.size
    video.oncanplay = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')!
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      setTimeout(async () => {
        ctx.drawImage(video, 0, 0)
        const url = canvas.toDataURL()
        try {
          const coverUrl = await base64ToImageUrl(url)
          res({
            coverUrl: coverUrl,
            duration: Math.round(video.duration),
            size: size
          })
        } catch {
          res({
            coverUrl: '',
            duration: Math.round(video.duration),
            size: size
          })
        } finally {
          window.URL.revokeObjectURL(url)
        }
      }, 500)
    }
  })
}
/**
 * @description 根据视频地址获取视频第一帧图片
 */
export const getVideoCoverByUrl = async (url: string): Promise<string> => {
  return new Promise((res, rej) => {
    const video = document.createElement('video')
    video.src = url
    video.crossOrigin = 'anonymous'
    video.currentTime = 1
    video.onloadeddata = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')!
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      setTimeout(async () => {
        ctx.drawImage(video, 0, 0)
        const url = canvas.toDataURL()
        try {
          const coverUrl = await base64ToImageUrl(url)
          coverUrl ? res(coverUrl) : rej();
        } catch {
          rej()
        }
      }, 500)
    }
    video.onerror = () => {
      rej()
    }
  })
}

/**
 * @description base64转png图片上传
 * @returns 上传图片地址
 */
export async function base64ToImageUrl(base64Data: string): Promise<string> {
  const blob = dataURLtoBlob(base64Data, mime.getType('png')!)
  const formData = new FormData();
  formData.append('file', new Blob([blob], {type: 'image/png'}), `${Math.random() + new Date().getTime()}.png`);
  const data = await axios.post(UPLOAD_URL, formData);
  return (data as any)?.data?.filename || null;
}


export function timestampToDateStr(timestamp: number, fmt: DateFormatType = "yyyy-MM-dd hh:mm:ss") {
  let date = new Date(timestamp * 1000);
  return DateFormatUtils.formatterDate(date, fmt);
}
