var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/provider/FormProvider/index.tsx
var FormProvider_exports = {};
__export(FormProvider_exports, {
  processLoader: () => processLoader,
  useValueTypeProvider: () => useValueTypeProvider
});
module.exports = __toCommonJS(FormProvider_exports);
var import_react = __toESM(require("react"));
var import_FormCascader = __toESM(require("../../components/FormCascader"));
var import_FormUpload = __toESM(require("../../components/FormUpload"));
var import_ServiceRequest = require("../../services/base/ServiceRequest");
var import_antd = require("antd");
var import_FormEditor = __toESM(require("../../components/FormEditor"));
var import_IconFontSelect = require("../../components/IconFontSelect");
var import_Link = __toESM(require("antd/es/typography/Link"));
var import_pro_components = require("@ant-design/pro-components");
var import_FormOptionEditor = __toESM(require("../../components/FormOptionEditor"));
var import_FormAttrEditor = __toESM(require("../../components/FormAttrEditor"));
var import_CustomComponent = __toESM(require("@/components/CustomComponent"));
var import_FormSelectPlus = __toESM(require("../../components/FormSelectPlus"));
var import_FormTags = __toESM(require("../../components/FormTags"));
var useValueTypeProvider = (formRef, actionRef) => {
  return {
    attr_: {
      render: (text) => {
        if (text) {
          const valueObj = JSON.parse(text);
          return /* @__PURE__ */ import_react.default.createElement(import_antd.Space, null, Object.keys(valueObj).map((key, index) => {
            return /* @__PURE__ */ import_react.default.createElement("span", { key: index }, key, ":", valueObj[key]);
          }));
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props) => {
        console.log("attr_ renderFormItem -> ", text, props);
        const { requestUrl } = props.fieldProps;
        let params = props.fieldProps.params;
        if (formRef) {
          const reg = /\${(.*?)}/g;
          const result = params == null ? void 0 : params.match(reg);
          result == null ? void 0 : result.forEach((paramName) => {
            var _a, _b;
            const key = paramName.substring(2, paramName.length - 1);
            console.log("key -> ", key, (_a = formRef.current) == null ? void 0 : _a.getFieldValue(key));
            params = params == null ? void 0 : params.replace(paramName, (_b = formRef.current) == null ? void 0 : _b.getFieldValue(key));
          });
        }
        return params ? /* @__PURE__ */ import_react.default.createElement(
          import_FormAttrEditor.default,
          {
            ...props,
            value: text,
            ...props == null ? void 0 : props.fieldProps,
            requestUrl,
            params
          }
        ) : /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, "无关联可选项");
      }
    },
    options_: {
      render: (text) => {
        if (text) {
          const valueArr = JSON.parse(text);
          return /* @__PURE__ */ import_react.default.createElement(import_antd.Space, null, valueArr.map((item, index) => {
            return /* @__PURE__ */ import_react.default.createElement("span", { key: index }, item[1]);
          }));
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props) => {
        return /* @__PURE__ */ import_react.default.createElement(import_FormOptionEditor.default, { ...props, value: text, ...props == null ? void 0 : props.fieldProps });
      }
    },
    cascader_: {
      render: (text) => /* @__PURE__ */ import_react.default.createElement("a", null, text),
      renderFormItem: (text, props, dom) => {
        const { loader } = props;
        console.debug("------------------code=" + (loader == null ? void 0 : loader.code));
        return /* @__PURE__ */ import_react.default.createElement(
          import_FormCascader.default,
          {
            dataRequest: { url: "/" + (loader == null ? void 0 : loader.code) + "/select", params: { level: 1 } },
            level: loader == null ? void 0 : loader.level
          }
        );
      }
    },
    images_: {
      render: (text) => {
        const value = text;
        if (value && value !== "") {
          const images = value.split(",");
          return /* @__PURE__ */ import_react.default.createElement(import_antd.Image.PreviewGroup, null, /* @__PURE__ */ import_react.default.createElement(import_antd.Space, null, images.map((image, index) => {
            return /* @__PURE__ */ import_react.default.createElement(
              import_antd.Image,
              {
                key: `img_${index}`,
                width: 96,
                src: image
              }
            );
          })));
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props, dom) => {
        const { valueEnum } = props;
        return /* @__PURE__ */ import_react.default.createElement(
          import_FormUpload.default,
          {
            valueType: "images_",
            options: valueEnum,
            value: text,
            ...props,
            ...props == null ? void 0 : props.fieldProps
          }
        );
      }
    },
    editor_: {
      render: (text, props) => {
        if (text) {
          return /* @__PURE__ */ import_react.default.createElement("a", { onClick: () => {
            import_antd.Modal.info({
              width: 1e3,
              title: "查看",
              content: /* @__PURE__ */ import_react.default.createElement(import_antd.Card, null, /* @__PURE__ */ import_react.default.createElement(import_FormEditor.default, { editable: false, value: text, ...props, ...props == null ? void 0 : props.fieldProps }))
            });
          } }, " 查看 ");
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props) => {
        return /* @__PURE__ */ import_react.default.createElement(import_FormEditor.default, { editable: true, value: text, ...props, ...props == null ? void 0 : props.fieldProps });
      }
    },
    iconFont_: {
      render: (text) => {
        if (text) {
          return /* @__PURE__ */ import_react.default.createElement(import_IconFontSelect.IconFont, { type: text });
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props) => {
        return /* @__PURE__ */ import_react.default.createElement(import_IconFontSelect.IconFontSelect, { value: text, ...props, ...props == null ? void 0 : props.fieldProps });
      }
    },
    link_: {
      render: (text) => /* @__PURE__ */ import_react.default.createElement(import_Link.default, { href: text, target: "_blank" }, text),
      renderFormItem: (text, props) => {
        return /* @__PURE__ */ import_react.default.createElement(import_pro_components.ProFormText, { ...props, initialValue: text, ...props == null ? void 0 : props.fieldProps });
      }
    },
    lookup_: {
      render: (text, props) => {
        var _a, _b;
        let isDetail = false;
        if ((_a = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _a.record) {
          props.record = (_b = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _b.record;
          isDetail = true;
        }
        if (text) {
          return /* @__PURE__ */ import_react.default.createElement(import_FormSelectPlus.default, { editable: false, isDetail, ...props });
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props) => {
        var _a, _b;
        let isEdit = false;
        if ((_a = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _a.record) {
          props.record = (_b = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _b.record;
          isEdit = true;
        }
        return /* @__PURE__ */ import_react.default.createElement(
          import_FormSelectPlus.default,
          {
            editable: true,
            isEdit,
            ...props,
            onChange: props.fieldProps.onChange,
            value: props.fieldProps.value
          }
        );
      }
    },
    custom_: {
      render: (text, props) => {
        var _a, _b;
        if ((_a = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _a.record) {
          props.record = (_b = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _b.record;
        }
        if (text) {
          return /* @__PURE__ */ import_react.default.createElement(import_CustomComponent.default, { editable: false, ...props, ...props == null ? void 0 : props.fieldProps });
        }
        return /* @__PURE__ */ import_react.default.createElement("span", null, "-");
      },
      renderFormItem: (text, props) => {
        var _a, _b;
        if ((_a = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _a.record) {
          props.record = (_b = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _b.record;
        }
        return /* @__PURE__ */ import_react.default.createElement(import_CustomComponent.default, { editable: true, ...props, ...props == null ? void 0 : props.fieldProps });
      }
    },
    tags_: {
      render: (text) => /* @__PURE__ */ import_react.default.createElement("span", null, text ? text : "-"),
      renderFormItem: (text, props) => {
        var _a;
        let isEdit = false;
        if ((_a = actionRef == null ? void 0 : actionRef.current) == null ? void 0 : _a.record) {
          isEdit = true;
        }
        return /* @__PURE__ */ import_react.default.createElement(import_FormTags.default, { isEdit, value: text, onChange: props.fieldProps.onChange, ...props });
      }
    }
  };
};
var uploadValueTypes = ["image", "video_", "file_", "files_"];
var processLoader = (columns) => {
  columns = columns == null ? void 0 : columns.map((column) => {
    var _a;
    const valueType = column.valueType;
    if (uploadValueTypes.includes(valueType)) {
      const { valueEnum, tooltip } = column;
      column.renderFormItem = (item, { type, defaultRender, ...rest }, form) => {
        return /* @__PURE__ */ import_react.default.createElement(import_FormUpload.default, { valueType, options: valueEnum });
      };
      column.tooltip = void 0;
    }
    if ((column == null ? void 0 : column.loader) && !((_a = column == null ? void 0 : column.loader) == null ? void 0 : _a.preload)) {
      let loader = column.loader;
      let dataRequest = { url: "" };
      let dataNames = {};
      if ((loader == null ? void 0 : loader.type) === "ITEM") {
        dataRequest.url = "/item/select";
        dataRequest.params = { code: loader.code };
      } else if ((loader == null ? void 0 : loader.type) === "DICT") {
        dataRequest.url = "/dict/select";
        dataRequest.params = { parentCode: loader.code };
        dataNames.id = loader.idKey || "code";
        dataNames.name = loader.nameKey || "name";
      } else if ((loader == null ? void 0 : loader.type) === "MANY_TO_ONE") {
        let queryString = loader.params;
        if (loader.inputType === "CASCADE") {
          column.renderFormItem = (item, { type, defaultRender, ...rest }, form) => {
            const loader2 = item.originProps.loader;
            console.log(rest);
            if (loader2) {
              return /* @__PURE__ */ import_react.default.createElement(
                import_FormCascader.default,
                {
                  width: item.originProps.width,
                  dataRequest: {
                    url: "/" + loader2.code + "/select" + (queryString ? "?" + queryString : ""),
                    params: { level: 1 }
                  },
                  level: loader2.level
                }
              );
            } else {
              return null;
            }
          };
        } else if (loader.inputType === "CUSTOM") {
          column.valueType = "custom_";
        } else if (loader.inputType === "LOOKUP") {
          column.valueType = "lookup_";
          dataRequest.url = "/" + loader.code + "/select" + (queryString ? "?" + queryString : "");
          dataNames.id = loader.idKey || "id";
          dataNames.name = loader.nameKey || "name";
        } else {
          dataRequest.url = "/" + loader.code + "/select" + (queryString ? "?" + queryString : "");
          dataNames.id = loader.idKey || "id";
          dataNames.name = loader.nameKey || "name";
        }
      }
      column = { ...column, request: (0, import_ServiceRequest.selectRequest)(dataRequest, dataNames) };
      console.log("processLoader column -> ", column);
    }
    return column;
  });
  return [...columns];
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  processLoader,
  useValueTypeProvider
});
