var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormTags/components/OakTag.tsx
var OakTag_exports = {};
__export(OakTag_exports, {
  default: () => OakTag_default
});
module.exports = __toCommonJS(OakTag_exports);
var import_antd = require("antd");
var import_react = __toESM(require("react"));
var import_icons = require("@ant-design/icons");
var tagInputStyle = {
  width: 64,
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: "top"
};
var FormTags = ({
  tagArray,
  tagChange
}) => {
  const [tags, setTags] = (0, import_react.useState)([]);
  const { token } = import_antd.theme.useToken();
  const [inputVisible, setInputVisible] = (0, import_react.useState)(false);
  const [inputValue, setInputValue] = (0, import_react.useState)("");
  const [editInputIndex, setEditInputIndex] = (0, import_react.useState)(-1);
  const [editInputValue, setEditInputValue] = (0, import_react.useState)("");
  const inputRef = (0, import_react.useRef)(null);
  const editInputRef = (0, import_react.useRef)(null);
  (0, import_react.useEffect)(() => {
    setTags(tagArray);
  }, [tagArray]);
  (0, import_react.useEffect)(() => {
    var _a;
    if (inputVisible) {
      (_a = inputRef.current) == null ? void 0 : _a.focus();
    }
  }, [inputVisible]);
  (0, import_react.useEffect)(() => {
    var _a;
    (_a = editInputRef.current) == null ? void 0 : _a.focus();
  }, [editInputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    tagChange == null ? void 0 : tagChange(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      let newTags = [...tags, inputValue];
      setTags(newTags);
      tagChange == null ? void 0 : tagChange(newTags);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    tagChange == null ? void 0 : tagChange(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };
  const tagPlusStyle = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: "dashed"
  };
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement(import_antd.Flex, { gap: "4px 0", wrap: "wrap" }, tags.map((tag, index) => {
    if (editInputIndex === index) {
      return /* @__PURE__ */ import_react.default.createElement(
        import_antd.Input,
        {
          ref: editInputRef,
          key: tag,
          size: "small",
          style: tagInputStyle,
          value: editInputValue,
          onChange: handleEditInputChange,
          onBlur: handleEditInputConfirm,
          onPressEnter: handleEditInputConfirm
        }
      );
    }
    const isLongTag = tag.length > 10;
    const tagElem = /* @__PURE__ */ import_react.default.createElement(
      import_antd.Tag,
      {
        key: tag,
        closable: true,
        style: { userSelect: "none" },
        onClose: () => handleClose(tag)
      },
      /* @__PURE__ */ import_react.default.createElement(
        "span",
        {
          onDoubleClick: (e) => {
            setEditInputIndex(index);
            setEditInputValue(tag);
            e.preventDefault();
          }
        },
        isLongTag ? `${tag.slice(0, 10)}...` : tag
      )
    );
    return isLongTag ? /* @__PURE__ */ import_react.default.createElement(import_antd.Tooltip, { title: tag, key: tag }, tagElem) : tagElem;
  }), inputVisible ? /* @__PURE__ */ import_react.default.createElement(
    import_antd.Input,
    {
      ref: inputRef,
      type: "text",
      size: "small",
      style: tagInputStyle,
      value: inputValue,
      onChange: handleInputChange,
      onBlur: handleInputConfirm,
      onPressEnter: handleInputConfirm
    }
  ) : /* @__PURE__ */ import_react.default.createElement(import_antd.Tag, { style: tagPlusStyle, icon: /* @__PURE__ */ import_react.default.createElement(import_icons.PlusOutlined, null), onClick: showInput }, "新增")));
};
var OakTag_default = FormTags;
