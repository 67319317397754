import {ProFieldFCRenderProps} from "@ant-design/pro-provider";
import WxMpMaterialLookup from "oak-weixin-admin-antd/lib/components/WxMpMaterialLookup";


const CustomComponent: React.FC<ProFieldFCRenderProps> = (props) => {
  let customType = props.fieldProps.customType;
  const showWxMpMaterialLookup = () => {
    const wxAppId = (props as any).params && (props as any).dependencies?.[0]
      ? (props as any).params[(props as any).dependencies?.[0]]
      : (props as any).record?.weixinAppId
        ? (props as any).record?.weixinAppId : '';
    return (
      customType === 'LOOKUP_MP_MEDIA' &&
        <WxMpMaterialLookup {...props} weixinAppId={wxAppId}/>
    )
  }
  return (
    <>
      {showWxMpMaterialLookup()}
    </>
  );
}

export default CustomComponent;
