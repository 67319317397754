/**
 * 剔除 boolean 值
 * @param  {boolean|T} obj
 * @returns T
 */
export var omitBoolean = function omitBoolean(obj) {
  if (obj && obj !== true) {
    return obj;
  }
  return undefined;
};