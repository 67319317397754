/**
 * We trade Map as deps which may change with same value but different ref object.
 * We should make it as hash for deps
 * */
export function stringify(obj) {
  var tgt;
  if (obj instanceof Map) {
    tgt = {};
    obj.forEach(function (v, k) {
      tgt[k] = v;
    });
  } else {
    tgt = obj;
  }
  return JSON.stringify(tgt);
}
var RC_TABS_DOUBLE_QUOTE = 'TABS_DQ';
export function genDataNodeKey(key) {
  return String(key).replace(/"/g, RC_TABS_DOUBLE_QUOTE);
}
export function getRemovable(closable, closeIcon, editable, disabled) {
  if (
  // Only editable tabs can be removed
  !editable ||
  // Tabs cannot be removed when disabled
  disabled ||
  // closable is false
  closable === false ||
  // If closable is undefined, the remove button should be hidden when closeIcon is null or false
  closable === undefined && (closeIcon === false || closeIcon === null)) {
    return false;
  }
  return true;
}