var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormSelectPlus/index.tsx
var FormSelectPlus_exports = {};
__export(FormSelectPlus_exports, {
  default: () => FormSelectPlus_default
});
module.exports = __toCommonJS(FormSelectPlus_exports);
var import_pro_components = require("@ant-design/pro-components");
var import_antd = require("antd");
var import_react = __toESM(require("react"));
var import_index = require("./index.less");
var import_ServiceRequest = require("../../services/base/ServiceRequest");
var import_Search = __toESM(require("antd/es/input/Search"));
var import_icons = require("@ant-design/icons");
var FormSelectPlus = ({
  editable = false,
  isDetail = false,
  isEdit = false,
  onChange,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = (0, import_react.useState)();
  const [pages, setPages] = (0, import_react.useState)({
    pageSize: 10,
    pageNo: 1,
    total: 0
  });
  const [newPages, setNewPages] = (0, import_react.useState)({
    pageSize: 10,
    pageNo: 1,
    total: 0
  });
  const [keyword, setKeyword] = (0, import_react.useState)("");
  const [previewInfo, setPreviewInfo] = (0, import_react.useState)({ show: false });
  const { message } = import_antd.App.useApp();
  const onVisibleChange = (value) => {
    setPreviewInfo((pre) => {
      return { ...pre, show: value };
    });
  };
  const {
    idKey,
    iconKey,
    nameKey,
    descriptionKey,
    contentKey,
    searchKey,
    pageable = false,
    actionType
  } = props.fieldProps;
  (0, import_react.useEffect)(() => {
    onChange == null ? void 0 : onChange((selectedItem == null ? void 0 : selectedItem.value) || void 0);
  }, [selectedItem]);
  (0, import_react.useEffect)(() => {
    reselect();
  }, [props.record]);
  const reselect = async () => {
    var _a;
    let record = props.record;
    if (record) {
      if (isEdit) {
        if (!idKey || !props.value) {
          return;
        }
        const data = await (0, import_ServiceRequest.request)(
          `/${(_a = props.fieldProps) == null ? void 0 : _a.code}/find`,
          {
            method: "post",
            data: { [idKey]: props.value },
            requestType: "form"
          }
        );
        if (!data) {
          message.error("数据不存在");
          return;
        }
        setSelectedItem({
          value: data[idKey],
          thumbImage: data[iconKey],
          title: data[nameKey],
          desc: data[descriptionKey],
          url: data[contentKey]
        });
      } else {
        setSelectedItem({
          value: record[idKey],
          thumbImage: record[iconKey],
          title: record[nameKey],
          desc: record[descriptionKey],
          url: record[contentKey]
        });
      }
    }
  };
  const getList = async (pagination, keyword2) => {
    var _a, _b;
    let queryString = (_a = props.fieldProps) == null ? void 0 : _a.params;
    const newPagination = {
      ...pages,
      ...pagination
    };
    const params = {
      pageSize: newPagination.pageSize,
      current: newPagination.pageNo
    };
    if (searchKey) {
      params[searchKey] = keyword2;
    }
    const resp = await (0, import_ServiceRequest.pageRequest)(`/${(_b = props.fieldProps) == null ? void 0 : _b.code}/page${queryString ? "?" + queryString : ""}`)(params);
    if (!resp.success) {
      message.error(resp.message);
      return;
    }
    newPagination.total = resp.total || 0;
    setPages(newPagination);
    return resp.data || [];
  };
  const handlePreviewClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewInfo((pre) => {
      return { ...pre, url, show: true };
    });
  };
  const getReadonlyImg = () => {
    if (!selectedItem) {
      return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null);
    }
    switch (actionType) {
      case "PREVIEW_VIDEO" /* PREVIEW_VIDEO */:
        if (selectedItem && selectedItem.thumbImage) {
          return /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(
            import_antd.Image,
            {
              width: 32,
              height: 32,
              src: selectedItem.thumbImage,
              preview: false,
              className: "justify-content-center cursor-pointer"
            }
          ));
        } else {
          return /* @__PURE__ */ import_react.default.createElement(
            "div",
            {
              className: "justify-content-center cursor-pointer",
              style: { width: 32, height: 32, display: "contents" }
            },
            /* @__PURE__ */ import_react.default.createElement(import_icons.VideoCameraOutlined, { style: { fontSize: 32 } })
          );
        }
      case "PLAY_AUDIO" /* PLAY_AUDIO */:
        return /* @__PURE__ */ import_react.default.createElement(
          "div",
          {
            className: "justify-content-center cursor-pointer",
            style: { width: 32, height: 32, display: "contents" }
          },
          /* @__PURE__ */ import_react.default.createElement(import_icons.CustomerServiceOutlined, { style: { fontSize: 32 } })
        );
      default:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem.thumbImage ? /* @__PURE__ */ import_react.default.createElement(
          import_antd.Image,
          {
            width: 32,
            height: 32,
            src: selectedItem.thumbImage,
            preview: false,
            className: "cursor-pointer"
          }
        ) : "-");
    }
  };
  const getOptionImg = (option) => {
    switch (actionType) {
      case "PREVIEW_VIDEO" /* PREVIEW_VIDEO */:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem && selectedItem.thumbImage || option ? /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(
          import_antd.Image,
          {
            width: option ? 36 : 24,
            height: option ? 36 : 24,
            src: option ? option.data.thumbImage : selectedItem.thumbImage,
            preview: false,
            className: "justify-content-center"
          }
        )) : /* @__PURE__ */ import_react.default.createElement(
          "div",
          {
            className: "justify-content-center",
            style: { width: option ? 36 : 24, height: option ? 36 : 24, display: "contents" }
          },
          /* @__PURE__ */ import_react.default.createElement(import_icons.VideoCameraOutlined, { style: { fontSize: option ? 36 : 24 } })
        ));
      case "PLAY_AUDIO" /* PLAY_AUDIO */:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, (selectedItem && selectedItem.thumbImage || option) && /* @__PURE__ */ import_react.default.createElement(
          "div",
          {
            className: "justify-content-center",
            style: { width: option ? 36 : 24, height: option ? 36 : 24, display: "contents" }
          },
          /* @__PURE__ */ import_react.default.createElement(import_icons.CustomerServiceOutlined, { style: { fontSize: option ? 36 : 24 } })
        ));
      default:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, (selectedItem && selectedItem.thumbImage || option) && /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(
          import_antd.Image,
          {
            className: "justify-content-center",
            width: option ? 36 : 24,
            height: option ? 36 : 24,
            src: option ? option.data.thumbImage : selectedItem.thumbImage,
            preview: false
          }
        )));
    }
  };
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement(
    import_antd.Image.PreviewGroup,
    {
      items: [previewInfo.url || ""],
      preview: {
        visible: previewInfo.show,
        destroyOnClose: true,
        countRender: () => null,
        onVisibleChange,
        imageRender: (originalNode, info) => {
          const current = info.current;
          if (actionType === "PREVIEW_VIDEO" /* PREVIEW_VIDEO */) {
            return /* @__PURE__ */ import_react.default.createElement("video", { muted: true, controls: true, src: previewInfo.url, style: { maxHeight: "80%", maxWidth: "80%" } });
          } else if (actionType === "PREVIEW_IMAGE" /* PREVIEW_IMAGE */) {
            return originalNode;
          } else if (actionType === "PLAY_AUDIO" /* PLAY_AUDIO */) {
            return /* @__PURE__ */ import_react.default.createElement("audio", { controls: true, src: previewInfo.url, style: { maxHeight: "80%", maxWidth: "80%" } });
          } else {
            return null;
          }
        }
      }
    },
    !editable && !isDetail && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem ? /* @__PURE__ */ import_react.default.createElement(import_antd.Tooltip, { title: selectedItem.title }, /* @__PURE__ */ import_react.default.createElement("div", { style: { width: "100%", textAlign: "center" } }, /* @__PURE__ */ import_react.default.createElement(
      "div",
      {
        style: { width: 32, height: 32, display: "inline" },
        onClick: (e) => {
          handlePreviewClick(e, selectedItem.url);
        }
      },
      getReadonlyImg()
    ))) : "-"),
    !editable && isDetail && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem ? /* @__PURE__ */ import_react.default.createElement(
      "div",
      {
        style: { display: "flex" },
        onClick: (e) => {
          handlePreviewClick(e, selectedItem.url);
        }
      },
      getReadonlyImg(),
      /* @__PURE__ */ import_react.default.createElement(
        "div",
        {
          className: "no-wrap justify-content-center",
          style: {
            marginLeft: 5,
            maxWidth: 200
          }
        },
        selectedItem.title
      )
    ) : "-"),
    editable && /* @__PURE__ */ import_react.default.createElement(
      import_pro_components.ProFormSelect,
      {
        ...props,
        params: {
          ...props.params,
          ...newPages,
          keyword
        },
        request: async (params) => {
          let result = !pageable ? await props.request() : await getList({
            pageSize: params.pageSize,
            pageNo: params.pageNo
          }, params.keyword || "");
          return (result == null ? void 0 : result.map((item) => {
            return {
              value: item[idKey],
              thumbImage: item[iconKey],
              title: item[nameKey],
              desc: item[descriptionKey],
              url: item[contentKey]
            };
          })) || [];
        },
        fieldProps: {
          ...props.fieldProps,
          onChange: (value, option) => {
            setSelectedItem(option == null ? void 0 : option["data-item"]);
          },
          labelRender: (item) => {
            if (!selectedItem) {
              return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null);
            }
            return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex" } }, getOptionImg(), /* @__PURE__ */ import_react.default.createElement(
              "div",
              {
                className: "no-wrap",
                style: {
                  marginLeft: 5,
                  maxWidth: 200
                }
              },
              selectedItem.title
            )));
          },
          optionRender: (option) => {
            return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex", justifyContent: "space-between" } }, /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex" } }, option.data.thumbImage && getOptionImg(option), /* @__PURE__ */ import_react.default.createElement("div", { style: { maxWidth: 170, paddingInlineStart: 10 } }, /* @__PURE__ */ import_react.default.createElement("div", { className: "no-wrap" }, option.data.title), option.data.desc && /* @__PURE__ */ import_react.default.createElement("div", { className: "no-wrap" }, option.data.desc))), option.data.url && /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(import_antd.Button, { type: "link", onClick: (e) => {
              handlePreviewClick(e, option.data.url);
            } }, actionType === "PLAY_AUDIO" /* PLAY_AUDIO */ ? "播放" : "预览"))));
          },
          dropdownRender: (menu) => {
            return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, menu, pageable && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement(import_antd.Divider, { style: { margin: "8px 0" } }), /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center", style: { cursor: "default" } }, searchKey && /* @__PURE__ */ import_react.default.createElement(import_Search.default, { allowClear: true, enterButton: true, onSearch: (value) => {
              setKeyword(value);
              setNewPages((pre) => {
                return { ...pre, pageNo: 1 };
              });
            }, style: { minWidth: "50%" } }), /* @__PURE__ */ import_react.default.createElement(
              import_antd.Pagination,
              {
                simple: { readOnly: true },
                size: "small",
                pageSize: pages.pageSize,
                current: pages.pageNo,
                total: pages.total,
                onChange: (pageNo, pageSize) => {
                  setNewPages({ pageNo, pageSize });
                }
              }
            ))));
          }
        }
      }
    )
  ));
};
var FormSelectPlus_default = FormSelectPlus;
